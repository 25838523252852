body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* width */
::-webkit-scrollbar {
  width: 2px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ededed;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #cbcbcb;
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #fd6900;
}

.truncate-2-lines {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Уменьшение шрифта в выпадающем списке и скрытие стрелки */
.custom-select {
  font-size: 12px; /* Установите нужный размер шрифта */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent; /* Убедитесь, что фон прозрачный */
  border: 1px solid #ccc; /* Добавьте границу, если необходимо */
  padding: 5px; /* Добавьте отступы, если необходимо */
  position: relative;
}

.custom-select::after {
  content: "";
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #000; /* Цвет стрелки */
  pointer-events: none;
}

.no-arrows::-webkit-outer-spin-button,
.no-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-arrows[type="number"] {
  -moz-appearance: textfield;
}
